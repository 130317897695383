@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Fragment+Mono&family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Fragment+Mono&family=Inter:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

:root {
    --theme-mg: #FEFDF5;
    --theme-dark-bg: #F8F6E8;
    /* --theme-mg: #eef6ff; */
}

html {
    font-family: 'Inter', system-ui, -apple-system;
}

.mono-font {
    font-family: 'IBM Plex Mono', monospace;
}

/* custom scrollbar */
::-webkit-scrollbar {
    width: 14px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #d4d4d4;
    border-radius: 7px;
    border: 4px solid transparent;
    background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #d4d4d4;
}

@layer components {
    .hover-lift:hover {
        @apply transition hover:scale-105 hover:shadow-xl;
    }

    .hover-shadow {
        @apply transition shadow-none hover:shadow-hov
    }

    .shadow-hov {
        @apply bg-neutral-300/50 transition

        /* box-shadow: 3px 3px 0px 0px rgba(167, 243, 208, 1);
        transform: translate(-1.5px, -1.5px); */
    }

    :hover > .btn-parent-hover {
        @apply shadow-hov;
    }

    .modal-shadow {
        @apply rounded;
        box-shadow: -2px 2px 1px 0 rgb(0 0 0);
    }

    .card, .card-shadow {
        box-shadow: 4px 4px 0px 0px rgba(215, 215, 215, 1);
    }
    

    .sticky-header-shadow {
        box-shadow: 0px 12px 12px 0 var(--white);
    }

    .banner-img {
        background-image: url('/public/banner.svg');
        background-repeat: repeat-x;
        height: 217px;
        min-height: 217px;
        border-right: 1.5px solid #64748b;
    }

    .duration-1250 {
        animation-duration: 1250ms !important;
        transition-duration: 1250ms;
    }
    .duration-1500 {
        animation-duration: 1500ms !important;
        transition-duration: 1500ms;
    }
    .duration-2000 {
        animation-duration: 2000ms !important;
        transition-duration: 2000ms;
    }
    .duration-2500 {
        animation-duration: 2500ms !important;
        transition-duration: 2500ms;
    }
    .duration-3000 {
        animation-duration: 3000ms !important;
        transition-duration: 3000ms;
    }
    .duration-3500 {
        animation-duration: 3500ms !important;
        transition-duration: 3500ms;
    }
    .duration-4000 {
        animation-duration: 4000ms !important;
        transition-duration: 4000ms;
    }
    .duration-5000 {
        animation-duration: 5000ms !important;
        transition-duration: 5000ms;
    }
}

input:not([type='checkbox']), textarea {
    background-color: var(--white) !important;
    border: 1px solid #c3c3c3 !important;
    border-radius: 5px !important;
    box-shadow: none !important;
}
